<template>
	<div class="not__found">
		<img
			class="not__found--image"
			src="@/assets/notfound.svg"
			alt="Not Found Image"
		/>
		<div class="not__found--text">
			<h1>ERROR!</h1>
			<p>Page not found!</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotFound'
};
</script>

<style lang="scss" scoped>
.not__found {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	&--image {
		height: 20rem;
		width: 20rem;
	}

	&--text {
		font-size: 3rem;
	}
}
</style>
